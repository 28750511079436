import React from 'react'
import { Heading, Text, Container } from '@hackclub/design-system'
import Layout from 'components/Layout'
import Nav from 'components/Nav'
import Name from 'components/Name'
import Bio from 'components/Bio'
import Footer from 'components/Footer'
import team from '../assets/imgs/team.jpg'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'
import { TeamHeader, TeamBase } from '../components/Content'

const title = 'Our Team - Coding Sastra'

export default ({ data }) => (
  <Layout
    bg="snow"
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/team/"
  >
    <Nav />
    <Social />
    <TeamHeader color="white">
      <Container maxWidth={36} align="center" py={[3, 4]}>
        <Heading.h1 fontSize={[3, 4]} regular caps mt={5} mb={2}>
          Coding Sastra Team
        </Heading.h1>
        <Name fontSize={6}>Meet Our Team</Name>
        <Text fontSize={3} my={3}></Text>
        <Name fontSize={6}>
          Believe in your passion, it drives your career.
        </Name>
      </Container>
    </TeamHeader>
    <TeamBase px={3} py={[4, 5]}>
      <Bio
        img={team}
        name="Varma Bhupatiraju"
        teamRole="FOUNDER & LEAD INSTRUCTOR"
        text="Varma Bhupatiraju, Founder & Lead Instructor at Coding Sastra has experience of 15 years+ in software development from client server to Web & Mobile software development projects. He has strong desire and passion to guide and mentor our young generation and share his experience to make them successful."
        pronouns="he/him"
      />
      <Bio
        img={team}
        name="Srinivas Raju Vuppalapati"
        teamRole="BLOCKCHAIN INSTRUCTOR"
        text="Srinivas Raju Vuppalapati, has experience of 17+ years in training and software development. He has strong passion to guide and mentor students in the latest technologies. He worked as Chief Coordinator, Incubation Center in a reputed Engineering College and he is an Certified Ethical Hacker from EC-Council , USA. He has vast knowledge in Web Technologies, Liunx Administration and Artificial Intelligence."
        pronouns="he/him"
      />
      <Bio
        img={team}
        name="Syam Vakkalanka"
        teamRole="ANDROID APP DEVELOPMENT INSTRUCTOR"
        text="Android Lead Developer for 2+ years experience in managing team of Android Developers. He is post graduate M.sc (Mathematics) from Andhra University, M.tech(Computer science ) from Nagarjuna University with more than 9 years of teaching experience and 2+ years of industrial experience."
        pronouns="he/him"
      />
      <Bio
        img={team}
        name="Krishna Allamraju"
        teamRole="PYTHON & IOS INSTRUCTOR"
        text="Krishna, an ECE graduate recognized his interest in mobile app development. He then worked on his skill, gained the expertise, turned into Senior iOS developer and also succeeded in leading the teams from the front. He has got 6.5+ years of experience in Information and Technology industry."
        pronouns="he/him"
      />
      <Bio
        img={team}
        name="Christopher Mathews (Madhu)"
        teamRole="HTML/CSS/RESPONSIVE DESIGN INSTRUCTOR"
        text="Madhu is a Senior Web Developer & UI Designer specializing in front end development. He is very well-versed in HTML, CSS3, JavaScript and ReactJS."
        pronouns="he/him"
      />
      <Bio
        img={team}
        name="Anil"
        teamRole="BUSINESS DEVELOPMENT MANAGER"
        text="He has 5+ years of corporate experience and 3+ years of NGO service has gained him invaluable interpersonal & soft skills. Anil envisions to see students taking up their career choices with confidence & boldness."
        pronouns="he/him"
      />
    </TeamBase>
    <Footer />
  </Layout>
)

export const query = graphql`
  query teamMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
